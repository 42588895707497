<template>
  <v-row>
    <v-col cols="12">
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Сегодня
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="500">
        <v-calendar
          weekdays="1, 2, 3, 4, 5, 6, 0"
          ref="calendar"
          :value="today"
          color="primary"
          v-model="focus"
        >
          <template v-slot:day-label="{ date }">
            <div
              class="single_date_rest"
              :class="{ todaysDate: date == today }"
            >
              {{ formatDate(date) }}
            </div>
          </template>
          <template v-slot:day="{ date }">
            <div
              @click="opendialog(date)"
              class="single_day"
              :class="{ disabledDay: !allowedDates(date) }"
            >
              <h3>{{ stocksPerDay(date) }}</h3>
            </div>
            <v-overlay
              :absolute="true"
              :opacity="0.7"
              :value="!stocksPerDay(date)"
            >
              <v-progress-circular
                :size="70"
                :width="2"
                color="amber"
                indeterminate
              />
            </v-overlay>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
    <v-overlay :absolute="true" :opacity="0.7" :value="overlay">
      <v-progress-circular :size="70" :width="7" color="amber" indeterminate />
    </v-overlay>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    overlay: false,
    stocksList: [],
    focus: "",
    today: new Date().toISOString().substr(0, 10),
    stockModel: {}
  }),
  async created() {
    this.stocksList = (
      await window.axios.get("/api/v2/booking/consumption/month/", {
        params: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1
        }
      })
    ).data;
  },
  methods: {
    allowedDates: val => val >= new Date().toISOString().substr(0, 10),
    async getCalendar(month, year) {
      try {
        this.stocksList = (
          await window.axios.get("/api/v2/booking/consumption/month/", {
            params: {
              year: year || new Date().getFullYear(),
              month: month || new Date().getMonth() + 1
            }
          })
        ).data;
      } catch (e) {
        console.log(e);
      }
    },
    opendialog(date) {
      console.log(date);
      this.$router.push(`/expenditure/${date}`);
    },
    prev() {
      this.$refs.calendar.prev();
      setTimeout(() => {
        this.getCalendar(
          this.$refs.calendar.lastStart.month,
          this.$refs.calendar.lastStart.year
        );
      }, 0);
    },
    next() {
      this.$refs.calendar.next();
      console.log(this.$refs.calendar);
      setTimeout(() => {
        this.getCalendar(
          this.$refs.calendar.lastStart.month,
          this.$refs.calendar.lastStart.year
        );
      }, 0);
    },
    setToday() {
      this.focus = "";
      this.getCalendar();
    },
    formatDate(date) {
      return moment(date).format("DD");
    },
    stocksPerDay(date) {
      let price = 0;
      this.stocksList.forEach(day => {
        if (day.date == date) {
          price = day.price;
        }
      });
      return `${price.toLocaleString("KZ-kz")} тенге`;
    }
  }
};
</script>

<style>
.todaysDate {
  background: #ff9800;
}
.single_day {
  cursor: pointer;
  text-align: center;
  padding-top: 20px;
  height: 100%;
  width: 100%;
  transition: 0.22s;
}
.disabledDay {
  background: #d3d3d3;
}
.single_day:hover {
  background-color: rgba(255, 152, 0, 0.4);
}
.dialog_buttons {
  width: 90%;
}
@media screen and (max-width: 600px) {
  .dialog_buttons {
    width: 98%;
  }
}
</style>
